import capitalize from 'lodash/capitalize';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  isFalsyString,
  isNullString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';

import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { casesStatusColors } from '@domains/CasesServices/const/status';
import { PRIORITIES_COLOR } from '@domains/CasesServices/const/priorities';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import CaseDetailsLink from '../CaseEvents/components/CaseDetailsLink';
import VehicleLink from '../CaseEvents/components/VehicleLink';

export const attributesToRetrieve = [
  'id',
  'uuid',
  'case_type_internal_name',
  'created_at',
  'reporting_user_id',
  'priority',
  'status',
  'entity_modified',
  'assignee_user_id',
  'assignee_user_uuid',
  'assignee_user_name',
  'user_id',
  'user_uuid',
  'user_name',
  'booking_fk',
  'booking_uuid',
  'vehicle_fk',
  'vehicle_uuid',
  'main_entity',
  'city_name',
  'reporting_user_name',
  'reporting_user_uuid',
  'linked_case_event_id',
  'linked_vehicle_damages',
  'created_at_ts',
  'vehicle_license_plate',
];

const getLink = (routeParamName, routeParamValue, routeName) => !isNullString(routeParamValue)
  ? {
    name: routeName,
    params: {
      [routeParamName]: routeParamValue,
    },
  }
  : null;

const userColumn = {
  attributeName: 'user_id',
  title: 'User',
  displayPriority: 2,
  minWidth: 180,
  type: RECORD_TYPES.component,
  component: PermissionLink,
  props: result => ({
    classLink: 'emobg-link-primary emobg-body-2',
    to: getLink('userUuid', result.user_uuid, crm.users.detail.cases),
    linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
    text: !isNullString(result.user_name) ? result.user_name : FALLBACK_MESSAGE.dash,
  }),
};

export function contentCells(operatorTimezone, isUserVisible = true) {
  const columns = [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: CaseDetailsLink,
      props: result => ({ result }),
    },
    {
      attributeName: 'case_type_internal_name',
      title: 'Case type',
      displayPriority: 1,
      minWidth: 220,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'created_at',
      title: 'Creation date',
      displayPriority: 1,
      minWidth: 180,
      transformValue: value => reformatDateTime(
        value,
        DATE_FORMAT.defaultExtended,
        operatorTimezone,
      ),
    },
    {
      attributeName: 'reporting_user_id',
      title: 'Created by',
      displayPriority: 1,
      minWidth: 140,
      transformValue: id => (id === 1 ? 'System' : 'User'),
    },
    {
      attributeName: 'priority',
      title: 'Priority',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ priority }) => ({
        text: sentenceCase(priority),
        color: PRIORITIES_COLOR[priority],
      }),
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      minWidth: 140,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: casesStatusColors[status],
      }),
    },
    {
      attributeName: 'entity_modified',
      title: 'Entity modified',
      displayPriority: 1,
      minWidth: 140,
      transformValue: value => (!isFalsyString(value) ? 'Yes' : 'No'),
    },
    {
      attributeName: 'assignee_user_id',
      title: 'Assignee',
      displayPriority: 2,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: getLink('userUuid', result.assignee_user_uuid, crm.users.detail.index),
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: !isNullString(result.assignee_user_name) ? result.assignee_user_name : FALLBACK_MESSAGE.dash,
      }),
    },
    {
      attributeName: 'booking_uuid',
      title: 'Booking Id',
      displayPriority: 2,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: getLink('bookingUuid', result.booking_uuid, carsharingRoutes.bookings.detail.index),
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        text: result.booking_fk ? `#${result.booking_fk}` : FALLBACK_MESSAGE.dash,
      }),
      minWidth: 70,
    },
    {
      attributeName: 'vehicle_fk',
      title: 'Vehicle Id',
      displayPriority: 2,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: VehicleLink,
      props: result => ({ result }),
    },
    {
      attributeName: 'main_entity',
      title: 'Main entity',
      displayPriority: 0,
      minWidth: 70,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'city_name',
      title: 'City',
      displayPriority: 0,
      minWidth: 70,
      transformValue: sentenceCase,
    },
    {
      title: 'Reporting user',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: getLink('userUuid', result.reporting_user_uuid, crm.users.detail.index),
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: !isNullString(result.reporting_user_name) ? result.reporting_user_name : FALLBACK_MESSAGE.dash,
      }),
    },
  ];

  if (isUserVisible) {
    columns.splice(8, 0, userColumn);
  }

  return columns;
}

export function caseEventsFacets(operatorTimezone) {
  return [
    {
      type: 'refinementList',
      props: {
        title: 'City',
        attributeName: 'city_name',
      },
    },
    {
      type: 'rangeDateTimePicker',
      props: {
        title: 'Created at',
        attributeName: 'created_at_ts',
        timezone: operatorTimezone || TIME_ZONE.default,
        from: null,
        labels: {
          removeButton: 'Clear dates',
        },

      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Main entity',
        attributeName: 'main_entity',
        transformValue: value => capitalize(value),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Case type',
        attributeName: 'case_type_internal_name',
        transformValue: value => capitalize(value).replace(/_/g, ' '),
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Status',
        attributeName: 'status',
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Entity modified',
        attributeName: 'entity_modified',
        transformValue: value => (!isFalsyString(value) ? 'Yes' : 'No'),
      },
    },
    {
      type: 'refinementOptions',
      props: {
        title: 'Created by',
        attributeName: 'reporting_user_id',
        showTooltip: true,
        options: [
          {
            label: 'System',
            value: '1',
          },
          {
            label: 'User',
            value: '-1',
          },
        ],
      },
    },
    {
      type: 'refinementAutocomplete',
      props: {
        title: 'Assignee',
        attributeName: 'assignee_user_name',
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Priority',
        attributeName: 'priority',
        transformValue: sentenceCase,
      },
    },
  ];
}
